<template>
  <div
    class="popup_wrap"
    style="width:650px;"
  >
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.NEWB020P030.003') }}</h1><!-- 첨부파일 확인 -->
      <div class="content_box">
        <h2 class="content_title">{{ setUploadTitle() }}</h2>
        <DEXT5Upload
          ref="dextupload"
          id="dextupload1"
          width="100%"
          height="200px"
          :file-info="uploadFileList"
          :category="true"
          :category-info="uploadCategoryInfo"
        />
      </div>
      <div class="mt10 text_center">
        <a
          class="button blue lg"
          @click.prevent="insertFile()"
        >{{ $t('msg.NEWB020P030.004') }}</a><!-- 저장 -->
      </div>
    </div>
  </div>
</template>

<script>

import trans from '@/api/rest/trans/trans'
import DEXT5Upload from '@/components/DEXT5Upload'
import DEXT5UploadFor from '@/components/common/DEXT5UploadFor'
import commons from '@/api/services/commons'

export default {
  name: 'IranUploadPop',
  components: { DEXT5Upload, DEXT5UploadFor },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          reqRno: '',
          count: 0,
          type: ''
        }
      }
    }
  },
  data () {
    return {
      params: {
        reqRno: '',
        count: '',
        type: '',
        popupType: 'IRAN_UPLOAD',
        // 첨부파일 리스트(실제 입력)
        uploadFileInfos: []
      },
      // Dext Uploader와 연결되어 있는 배열
      uploadFileList: [],
      uploadCategoryInfo: [],
      commonCodes: {}
    }
  },
  created () {
    this.initPopup()
  },
  methods: {
    async initPopup () {
      // 필수값 체크 (test 위한 validation)
      if (this.parentInfo.reqRno === '' || this.parentInfo.type === '') {
        await this.$ekmtcCommon.asyncAlertMessage({ message: '필수값을 입력하세요. (reqRno, type)' }) // 필수값을 입력하세요. (reqRno, type)
        this.$emit('close')
        return
      }

      this.params = { ...this.params, ...this.parentInfo }

      // 카테고리 추가
      const category1 = { category: 'LOI', value: '01' }
      const category2 = { category: 'Non-Strategy Certi', value: '02' }

      this.uploadCategoryInfo.push(category1)
      this.uploadCategoryInfo.push(category2)

      await this.getIranUploadList()
    },
    // 이란 업로드 파일 조회
    async getIranUploadList () {
      await trans.getIranUpload(this.params).then(response => {
        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.iranUploadList

          if (fileList !== undefined) {
            fileList.forEach(file => {
              this.uploadFileList.push(
                {
                  guid: file.bkgNo + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + file.bkgNo + '&atchFileSeq=' + file.fileSeq + '&fileTypCd=' + file.fileTypCd + '&type=IRAN_UPLOAD',
                  atchFileSeq: file.fileSeq,
                  fileTypCd: file.fileTypCd,
                  category: file.fileKndCd
                }
              )
            })
          }
        }
      })
    },
    // 저장 or 수정
    async insertFile () {
      const TH = this
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.NEWB020P030.008'), useConfirmBtn: true })) {
        await TH.onConfirmInsert()
      }
    },
    // 저장 Confirm '확인' 클릭 시 콜백 메소드
    async onConfirmInsert () {
       if (this.validationCategory()) {
      // 상위 컴포넌트에서 가져온 데이터를 실제 입력할 parameter에 담는다.
      // this.params = { ...this.params, ...this.parentInfo }
      // 실제 입력할 파일리스트를 담는다.
        this.params.uploadFileInfos = this.uploadFileList

        // 새로 추가된 파일은 json에 'newFlag' 값을 부여해준다. ('new'는 뒷단에서 예약어 이므로 새로 추가)
        this.params.uploadFileInfos.map((item) => (item.newFlag = item.new))

        // 기존 파일은 fileId에 다운로드 링크가 들어 있어서
        // 뒷단에서 Data binding 할 때, 오류가 나서 값을 비워줘야 한다.
        this.params.uploadFileInfos.forEach(element => {
          if (element.newFlag === undefined || element.newFlag === '') {
            element.fileId = ''
          }
        })

        // 파일 저장(Booking 등록)
        await trans.insertIranUpload(this.params).then(response => {
          let result = response.data
          if (response.headers.respcode === 'C0000' && result !== undefined && result.resultCode === 'SUCC') {
            const TH = this

            result.uploadFileInfos = this.params.uploadFileInfos

            TH.$ekmtcCommon.alertCallback(TH.$t('msg.NEWB020P030.009'), function () {
              TH.$emit('close')
            })
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.NEWB020P030.010'))
          }
        })
       }
    },
    setUploadTitle () {
      let title = ''
      const parentInfo = this.parentInfo
      const type = parentInfo.type

      if (type === '01') {
        title = this.$t('msg.NEWB020P030.013') // LOI
      } else if (type === '02') {
        title = this.$t('msg.NEWB020P030.014') // COMFIRMATION
      } else {
        title = this.$t('msg.NEWB020P030.015') // CERTIFICATE OFEND-USE ASSURANCE
      }

      return title
    },
    validationCategory () {
      let isOk = true
      // 파일리스트 카테고리 선택 여부 검사
      // this.parentInfo.uploadFileList.forEach(element => {
      this.uploadFileList.forEach(element => {
        if (element.category === undefined || element.category === '') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050P170.009')) // 카테고리가 선택되지 않은 파일이 있습니다.
          isOk = false
          return false
        }
      })

      return isOk
    }
  }
}
</script>
